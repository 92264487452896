import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faNewspaper,
  faLaptop,
} from "@fortawesome/free-solid-svg-icons";

const Topbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar se o menu está aberto ou fechado

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav>
      <div className="md:hidden flex justify-between items-center">
        <FontAwesomeIcon
          icon={faBars}
          onClick={toggleMenu}
          className="text-white cursor-pointer"
        />
      </div>

      <ul
        className={`${
          isMenuOpen ? "flex" : "hidden"
        } flex-col md:flex md:flex-row md:space-x-6 mt-4 md:mt-0 text-white items-start`}
      >
        <li>
          <a
            className="hover:text-secondary flex items-center space-x-2 py-2"
            href="https://www.instagram.com/psi.annacarolina/"
            onclick="gtag('event', 'click', {
              'event_category': 'Link Click',
              'event_label': 'Instagram'
            });"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
            <span className="ml-2">Instagram</span>
          </a>
        </li>
        <li>
          <a
            className="hover:text-secondary flex items-center space-x-2 py-2"
            href="#servicos"
          >
            <FontAwesomeIcon icon={faLaptop} />
            <span className="ml-2">Serviços</span>
          </a>
        </li>
        <li>
          <a
            className="hover:text-secondary flex items-center space-x-2 py-2"
            href="https://msha.ke/psi.annacarolina"
            onclick="gtag('event', 'click', {
              'event_category': 'Link Click',
              'event_label': 'Psi na mídia'
            });"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faNewspaper} />
            <span className="ml-2">Psi na Mídia</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Topbar;
