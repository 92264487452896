import "./App.css";
import Bar from "./components/grid/header/Bar";
import Content from "./components/grid/banner/Content";
import Services from "./components/Services";

function App() {
  return (
    <>
      <Bar />
      <Content />
      <Services />
    </>
  );
}

export default App;
